@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    -webkit-tap-highlight-color:transparent;
}
body {
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    }
    
html {
    height: -webkit-fill-available;
}
.ringStory {
    border: double 3px transparent;
    background-image: linear-gradient(white, rgb(255, 255, 255)), 
                      linear-gradient(to right, orange,rgb(235, 92, 49), rgb(247, 100, 56),rgb(250, 92, 92));
                      
    background-clip: padding-box, border-box;
  }
.instalogo >SVG{
    width: 75%;
    height: 50px;
    margin: auto;
}
.bg{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
/* .gradient{
    background: rgb(131,58,180);
    background: linear-gradient(97deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
} */
/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollBare::-webkit-scrollbar {
    display: none;
}
  
  /* Hide scrollbar for IE, Edge and Firefox */
.hideScrollBare {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.infinite-scroll-component__outerdiv{
    height: 100%;
}
.swiper-wrapper{
  height: 100% !important;
}
.ellipse-2{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}
@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }.inputfield {
    @apply
    text-sm font-medium text-gray-900 border py-3 pl-3 rounded-md mb-3 bg-zinc-50 focus:outline-none focus:border-gray-500 block placeholder:font-extralight
}
.selectfield {
    @apply
    text-sm font-medium text-gray-900 border py-3 px-0 pl-3 rounded-md mb-3 bg-zinc-50 focus:outline-none focus:border-gray-500 block placeholder:font-extralight appearance-none border-gray-300
}
.btn-primary{
    @apply
    bg-blue-500 rounded-md text-base font-bold text-white py-2 mb-3 focus:bg-blue-400 
}

.Searchbar{
    @apply
    block p-3 w-80 pl-10 outline-none text-sm text-gray-900 bg-gray-100  rounded-xl placeholder:font-extralight placeholder:text-base
}
.dropdown-items{
    @apply
    text-gray-700 px-4 py-3 text-base font-poppins flex gap-3 hover:bg-gray-100
}
.profileitems {
    @apply
    flex gap-3 items-center pt-5 text-base font-medium text-gray-500 uppercase cursor-pointer
}